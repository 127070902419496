import React from "react";
import "../index.css";
import GetLandingForm from "../../../component/getLandingForm"
import BenifitsOfOutsourcing from "../../../component/benifitsOfOutsourcing"
import HiringIsEasy from "../../../component/hiringIsEasy"
import ReadyToScale from "../../../component/serviceReadyToScale"
import ClientsWords from "../../../component/ClientWord";
import ServiceWhyChoose from "../../../component/serviceWhyChoose";
import ServiceBenefits from "../../../component/serviceBenefits";
import {Helmet} from "react-helmet";

const AnsweringService = () => {
    return (
        <>
        <Helmet>
            <title>Answering Services | Communication Solutions | Business Process Outsourcing BPO | Customer Interactions - Staffwiz</title>
            <meta name="description" content="Enhance customer interactions with professional answering services. Staffwiz offers tailored communication solutions and business process outsourcing (BPO) for your business needs." />
        </Helmet>
           <section className="home-banner  careers-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6">
                                    <h1 className="answerringH11">
                                        Answering{'\n'} Services
                                    </h1 >
                                    <p>Many companies turn to Business Process Outsourcing (BPO) answering services to streamline their customer interactions and improve overall productivity. Among the leading players in this industry, Staffwiz stands out as a reliable partner that offers tailored solutions to businesses of all sizes</p>
                                    
                                </div>
                                <div className="col-lg-6 right-info">
                                    <img
                                        className="img-fluid"
                                        src="assets/images/ansservice.png"
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    <section className="service-form">
    <img
                                        className="img-fluid dsgn-img1"
                                        src="assets/images/BPO Service Materials 1.png"
                                    ></img>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6 pd30">
                                    <GetLandingForm heading={"Elevate Your Business with Answering Services by Staffwiz"} />
                                </div>
                        
                                <div className="col-lg-6 right-inf pd30">
                                <ServiceWhyChoose title={"Answering Services"} />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
         <img
                                        className="img-fluid dsgn-img2"
                                        src="assets/images/2 565.png"
                                    ></img>
            </section>
     <ServiceBenefits title={"answering services"} />
     <div className="container realizeP">
                    <div className="row">
                    <div className="col-lg-9 mx-auto">
     <p>Unlock unparalleled efficiency in customer interactions with Staffwiz! Our tailored answering services and cutting-edge communication solutions, grounded in business process outsourcing (BPO) expertise, ensure seamless, top-notch customer experiences. Contact Staffwiz today for a transformative approach to enhancing your customer interactions and optimizing business processes.</p>
           </div>
   <div className="col-lg-3 mx-auto">
   </div>
           </div>
            </div>
            <ClientsWords />
           

            {/* <HireTalent /> */}
            <ReadyToScale />


        </>

    );
};
export default AnsweringService;
