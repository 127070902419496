import React, { useState, useEffect } from "react";
import "./index.css";
import GetStartedForm from "../../component/homeForm"
import { useLocation } from 'react-router-dom';
import GetStartForm from "../../component/getStartForm"
import HireTalent from "../../component/hireTalent"
import { PopupButton } from "react-calendly";
import { APP_CONFIG } from "../../config/config";
import {Helmet} from "react-helmet";

const SkilledProfessionals = () => {
    const location = useLocation();
    const [data, setData] = useState({})

    const [listData, setListData] = useState({
        "/healthcare-service": {
            mainHeading: "BPO Healthcare patient-centric solutions",
            about: "At StaffWiz Healthcare BPO, we recognize the critical role that exceptional support plays in the healthcare industry. As the healthcare landscape evolves, so do the demands for efficient and compassionate customer service. We specialize in providing comprehensive BPO call center services designed to meet the unique needs of healthcare organizations, ensuring unparalleled support that prioritizes patient satisfaction and operational excellence.",
            bannerImage: "Untitled-2.png",
            aboutImage: "a1.png",
            whyChoose: "Why Choose StaffWiz for Your Healthcare BPO Call Center Solutions?",
            whyChooseh1: "Healthcare-Centric Expertise:",
            whyChoosep1: "Our healthcare team, experts in billing, patient care, and administration, provides specialized operational support.",
            whyChooseh2: "Compliance and Confidentiality:",
            whyChoosep2: "In healthcare, StaffWiz ensures strict adherence to data protection regulations, prioritizing patient privacy and compliance.",
            whyChooseh3: "24/7 Patient Support:",
            whyChoosep3: "Our 24/7 patient support ensures assistance and information anytime, fostering high satisfaction and loyalty.",
            whyChooseh4: "Multilingual Services:",
            whyChoosep4: "We offer multilingual healthcare support for inclusive and accessible communication with diverse patients.",
            whyChooseh5: "Appointment Scheduling and Reminders:",
            whyChoosep5: "Enhance appointment management with our dedicated teams, boosting patient engagement and reducing no-shows.",
            whyChoose2: "Our Healthcare BPO Call Center Services Include:",
            whyChoose2h1: "Patient Enrollment and Registration:",
            whyChoose2p1: "Smooth onboarding of patients through efficient enrollment and registration processes.",
            whyChoose2h2: "Insurance Verification:",
            whyChoose2p2: "Verify and validate insurance information to facilitate seamless billing processes.",
            whyChoose2h3: "Medical Billing and Coding Support:",
            whyChoose2p3: "Navigate the complexities of medical billing and coding with  r specialized assistance, optimizing revenue cycles.",
            whyChoose2h4: "Telehealth Support:",
            whyChoose2p4: "Enhance your telehealth services with our trained professionals offering technical support and appointment coordination.",
            whyChoose2h5: "Prescription Refill Management:",
            whyChoose2p5: "Streamline prescription refill processes, ensuring timely medication for patients.",
            exprienceh: "Experience the StaffWiz Advantage in Healthcare BPO",
            expriencep: "Partnering with StaffWiz means partnering with a team dedicated to the well-being of your patients and the success of your healthcare organization. Elevate your patient-centric approach with our customized BPO call center services. Contact us today to explore how we can tailor our solutions to meet the unique needs of your healthcare operations.",
            title: "Healthcare BPO Services | Patient Support Services | Healthcare Business Process Outsourcing | Healthcare BPO - Staffwiz",
            desc: " Optimize your operations with healthcare BPO services from Staffwiz. We specialize in patient support services, healthcare business process outsourcing, and comprehensive healthcare BPO solutions.",
            listContent: [
                "Medical Encoding",
                "Nurses / Remote Patient Monitoring",
                "Clinical Abstracting",
                "Medical transcriptionist",
                "Medical Billing",
                "Clinic Admin/ Scheduling",
                "Medical Claims Administration",
                "Insurance / Billing / Vaccine",
                "Telephone Triage Nurse",
                "Scheduling / Claims / Verification"
            ]
        },
        "/fintech-service": {
            mainHeading: "Your Trusted Partner in Fintech BPO Call Center Services",
            about: "At StaffWiz, we understand the unique demands and challenges faced by the dynamic fintech industry. With the ever-evolving landscape of financial technology, customer service excellence is not just a necessity; it's a strategic imperative. Our BPO call center services are specifically tailored to meet the intricate needs of fintech companies, providing unparalleled support that aligns seamlessly with your business objectives.",
            bannerImage: "Untitled-3.png",
            aboutImage: "a2.png",
            whyChoose: "Why Choose StaffWiz for Your Fintech BPO Call Center Needs?",
            whyChooseh1: "Customer Support:",
            whyChoosep1: "Dedicated teams proficient in addressing customer inquiries, troubleshooting technical issues, and providing personalized support.",
            whyChooseh2: "Transaction Verification:",
            whyChoosep2: "Ensuring the security and accuracy of financial transactions through meticulous verification processes.",
            whyChooseh3: "Fraud Prevention:",
            whyChoosep3: "Implementing robust measures to identify and prevent fraudulent activities, safeguarding your business and customers.",
            whyChooseh4: "Multichannel Support:",
            whyChoosep4: "We provide omnichannel support through phone, email, chat, and social media for a seamless customer experience.",
            whyChooseh5: "Scalability:",
            whyChoosep5: "Fintech firms grow fast with our scalable solutions, ensuring seamless expansion without compromising service quality.",
            whyChoose2: "Our Fintech BPO Call Center Services Include",
            whyChoose2h1: "Customer Support:",
            whyChoose2p1: "Dedicated teams proficient in addressing customer inquiries, troubleshooting technical issues, and providing personalized support.",
            whyChoose2h2: "Transaction Verification:",
            whyChoose2p2: "Ensuring the security and accuracy of financial transactions through meticulous verification processes.",
            whyChoose2h3: "Fraud Prevention:",
            whyChoose2p3: "Implementing robust measures to identify and prevent fraudulent activities, safeguarding your business and customers.",
            whyChoose2h4: "Collections:",
            whyChoose2p4: "Managing overdue accounts with a strategic and customer-centric approach, optimizing debt recovery processes.",
            whyChoose2h5: "Data Analytics:",
            whyChoose2p5: "Utilizing data-driven insights to enhance customer experiences, optimize operations, and drive informed decision-making.",
            exprienceh: "Partner with StaffWiz for Fintech Excellence",
            expriencep: "At StaffWiz, we go beyond outsourcing – we become an extension of your team, dedicated to elevating your fintech operations. Experience the difference of a BPO call center service provider that is committed to your success. Contact us today to explore how we can tailor our services to meet your unique needs and propel your fintech business forward.",
            title: "Fintech Service | StaffWiz",
            desc: " ",
            listContent: [
                "Medical Encoding",
                "Nurses / Remote Patient Monitoring",
                "Clinical Abstracting",
                "Medical transcriptionist",
                "Medical Billing",
                "Clinic Admin/ Scheduling",
                "Medical Claims Administration",
                "Insurance / Billing / Vaccine",
                "Telephone Triage Nurse",
                "Scheduling / Claims / Verification"
            ]

        },
        "/ecommerce-service": {
           mainHeading: "Empowering Growth at StaffWiz Ecommerce Support",
            about: "In the dynamic world of Ecommerce, where every click counts, customer support is the heartbeat of success. At StaffWiz, we specialize in providing tailored BPO call center services for Ecommerce businesses, ensuring that your customers receive unparalleled support and your operations run seamlessly. Elevate your online shopping experience with StaffWiz as your trusted partner.",
            bannerImage: "Untitled-4.png",
            aboutImage: "a3.png",
            whyChoose: "Why Opt for StaffWiz Ecommerce Support?",
            whyChooseh1: "Ecommerce Experts at Your Service:",
            whyChoosep1: "Our Ecommerce specialists provide tailored assistance for your online retail needs, from product inquiries to order management.",
            whyChooseh2: "Order Fulfillment Excellence:",
            whyChoosep2: "StaffWiz ensures fast and accurate Ecommerce order processing for enhanced customer satisfaction.",
            whyChooseh3: "Multichannel Customer Support:",
            whyChoosep3: "We offer omnichannel support across phone, email, chat, and social media to engage customers on their preferred platforms.",
            whyChooseh4: "24/7 Assistance:",
            whyChoosep4: "Our 24/7 customer support builds loyalty and trust by assisting your customers whenever they need it.",
            whyChooseh5: "Returns and Refund Management:",
            whyChoosep5: "Streamline returns and refund processes, transforming potential challenges into opportunities to enhance customer satisfaction and loyalty.",
            whyChoose2: "Our Ecommerce BPO Call Center Services Include:",
            whyChoose2h1: "Product Information and Assistance:",
            whyChoose2p1: "Detailed product knowledge and expert assistance for customers looking to make informed purchase decisions.",
            whyChoose2h2: "Order Tracking and Status Updates:",
            whyChoose2p2: "Keep your customers informed about the status of their orders, enhancing transparency and satisfaction.",
            whyChoose2h3: "Inventory Management Support:",
            whyChoose2p3: "Efficiently manage your inventory with our support, ensuring accurate product availability information.",
            whyChoose2h4: "Cart Abandonment Recovery:",
            whyChoose2p4: "Implement strategies to recover abandoned carts and minimize lost sales opportunities.",
            whyChoose2h5: "Technical Support:",
            whyChoose2p5: "Resolve technical issues promptly, ensuring a smooth online shopping experience for your customers.",
            exprienceh: "Partner with StaffWiz for Ecommerce Success",
            expriencep: "At StaffWiz, we don't just provide support; we become an extension of your Ecommerce team, committed to driving growth through exceptional customer experiences. Elevate your Ecommerce operations with our customized BPO call center services. Contact us today to explore how we can tailor our solutions to suit the unique needs of your Ecommerce business.",
            title: "Ecommerce Customer Service Outsourcing | Shopify Customer Service Chat | Ecommerce BPO Outsourcing Services - Staffwiz",
            desc: "Elevate your online business with ecommerce customer service outsourcing from Staffwiz. We offer Shopify customer service chat and comprehensive ecommerce BPO outsourcing services.",
        },
        "/retail-service": {
            mainHeading: "Enhancing Retail Experiences with StaffWiz Solutions",
            about: "In the fast-paced and competitive landscape of retail, delivering exceptional customer experiences is the key to success. At StaffWiz, we bring a wealth of expertise in providing BPO call center services specifically tailored for the retail sector. Elevate your customer interactions, streamline operations, and boost customer loyalty with our dedicated retail support solutions.",
            bannerImage: "Untitled-5.png",
            aboutImage: "a4.png",
            whyChoose: "Why Choose StaffWiz for Your Retail Support?",
            whyChooseh1: "Retail Specialists on Your Team:",
            whyChoosep1: "Our experts understand both brick-and-mortar and e-commerce retail, delivering specialized support tailored to your business needs.",
            whyChooseh2: "Omnichannel Customer Engagement:",
            whyChoosep2: "StaffWiz ensures seamless customer interactions across multiple channels in today's retail landscape.",
            whyChooseh3: "Inventory Management Assistance:",
            whyChoosep3: "Effortlessly manage inventory for accurate availability and prevent stockouts.",
            whyChooseh4: "Order Management Excellence:",
            whyChoosep4: "Streamline order processing, tracking, and status updates for accurate and timely customer deliveries.",
            whyChooseh5: "Returns and Exchange Support:",
            whyChoosep5: "Simplify returns and exchanges to demonstrate your dedication to customer satisfaction.",
            whyChoose2: "Our Retail BPO Call Center Services Include:",
            whyChoose2h1: "Product Information and Recommendations:",
            whyChoose2p1: "Equip your customers with detailed product information and personalized recommendations to enhance their shopping experience.",
            whyChoose2h2: "Promotion and Loyalty Program Support:",
            whyChoose2p2: "Communicate promotions and manage loyalty programs effectively, fostering customer loyalty and repeat business.",
            whyChoose2h3: "Customer Feedback Management:",
            whyChoose2p3: "Gather valuable insights through customer feedback management, helping you refine your offerings and services.",
            whyChoose2h4: "Complaint Resolution:",
            whyChoose2p4: "Swiftly address customer concerns and resolve issues, turning negative experiences into positive ones.",
            whyChoose2h5: "Holiday and Seasonal Support:",
            whyChoose2p5: "Scale up operations during peak seasons with our flexible solutions, ensuring a seamless shopping experience for your customers.",
            exprienceh: "Partner with StaffWiz for Retail Excellence",
            expriencep: "At StaffWiz, we go beyond providing support; we become an integral part of your retail team, dedicated to enhancing customer experiences and driving retail success. Elevate your retail operations with our customized BPO call center services. Contact us today to explore how we can tailor our solutions to meet the unique needs of your retail business.",
            title: "Retail Support Services | Retail BPO - Staffwiz",
            desc: "Enhance your retail operations with expert retail support services from Staffwiz. We provide specialized retail BPO solutions to optimize your business processes.",

        },
        "/travel-support-service": {
            mainHeading: "Optimizing Journeys with Ultimate Travel Support",
            about: "In the vibrant world of travel, where each journey is a unique adventure, exceptional customer support is the compass that guides memorable experiences. StaffWiz specializes in providing BPO call center services tailored for the travel industry. Whether it's booking assistance, itinerary changes, or travel advice, trust StaffWiz to be your reliable partner in delivering top-notch travel support.",
            bannerImage: "Untitled-6.png",
            aboutImage: "a5.png",
            whyChoose: "Why Opt for StaffWiz Travel Support?",
            whyChooseh1: "Travel Enthusiasts on Your Team:",
            whyChoosep1: "Passionate professionals with industry expertise offer personalized support for diverse traveler needs.",
            whyChooseh2: "Multichannel Assistance:",
            whyChoosep2: "StaffWiz ensures consistent travel assistance across phone, email, chat, and social media with multichannel support.",
            whyChooseh3: "24/7 Traveler Support:",
            whyChoosep3: "Our 24/7 support ensures stress-free and memorable journeys for your customers anytime, anywhere.",
            whyChooseh4: "Flight and Accommodation Management:",
            whyChoosep4: "Effortlessly handle booking inquiries, flight changes, and accommodation adjustments, ensuring smooth transitions for travelers.",
            whyChooseh5: "Travel Insurance Support:",
            whyChoosep5: "Provide clear and concise information about travel insurance, assist with claims, and offer peace of mind to your customers throughout their journeys.",
            whyChoose2: "Our Travel BPO Call Center Services Include:",
            whyChoose2h1: "Booking Assistance:",
            whyChoose2p1: "Expert guidance in booking flights, accommodations, and other travel essentials to help customers make informed decisions.",
            whyChoose2h2: "Itinerary Changes and Updates:",
            whyChoose2p2: "Swift handling of itinerary modifications, ensuring flexibility and ease for travelers.",
            whyChoose2h3: "Destination Information:",
            whyChoose2p3: "Provide detailed information about travel destinations, including attractions, local customs, and essential travel tips.",
            whyChoose2h4: "Lost Luggage and Traveler Assistance:",
            whyChoose2p4: "Efficiently manage lost luggage situations and provide comprehensive traveler assistance during unforeseen circumstances.",
            whyChoose2h5: "Travel Rewards Program Support:",
            whyChoose2p5: "Communicate details about travel rewards programs, assist with point redemption, and enhance customer engagement.",
            exprienceh: "Partner with StaffWiz for Seamless Travel Experiences",
            expriencep: "At StaffWiz, we don't just provide travel support; we become an integral part of your travelers' journeys, dedicated to enhancing their experiences. Elevate your travel services with our customized BPO call center solutions. Contact us today to explore how we can tailor our services to meet the unique needs of your travel business.",
            title: "Travel Account Call Center | Travel Call Center | Travel Agency Call Cente - Staffwiz",
            desc: "Enhance your customer service with travel call center outsourcing from Staffwiz. We specialize in travel account call centers and travel agency call center solutions to support your business.",

        },
        "/insurance-service": {
            mainHeading: "Insurance Support Safeguards Confidence and Enhances Trust",
            about: "In the complex landscape of insurance, where protection and peace of mind are paramount, exceptional customer support is the cornerstone of a strong client-insurer relationship. StaffWiz specializes in providing BPO call center services tailored for the insurance industry. From policy inquiries to claims assistance, trust StaffWiz to be your reliable partner in delivering top-tier support for your policyholders.",
            bannerImage: "Untitled-7.png",
            aboutImage: "a6.png",
            whyChoose: "Why Trust StaffWiz for Your Insurance Support?",
            whyChooseh1: "Insurance Experts at Your Service:",
            whyChoosep1: "Our experienced team specializes in providing tailored support for your insurance policies, building trust and confidence.",
            whyChooseh2: "Compliance and Confidentiality:",
            whyChoosep2: "In insurance, StaffWiz ensures compliance and confidentiality by strictly following data protection regulations for policyholder security.",
            whyChooseh3: "Multichannel Support:",
            whyChoosep3: "We offer seamless omnichannel support via phone, email, chat, and social media for a consistent policyholder experience.",
            whyChooseh4: "Claims Processing Excellence:",
            whyChoosep4: "Efficiently handle claims inquiries, guiding policyholders through the process and expediting the resolution of their claims.",
            whyChooseh5: "Policy Information and Coverage Details:",
            whyChoosep5: "Provide clear and detailed information about policies, coverage details, and answer inquiries to enhance policyholder understanding.",
            whyChoose2: "Our Insurance BPO Call Center Services Include:",
            whyChoose2h1: "Policyholder Onboarding:",
            whyChoose2p1: "Smoothly guide new policyholders through the onboarding process, ensuring a positive first impression.",
            whyChoose2h2: "Renewal Reminders and Assistance:",
            whyChoose2p2: "Proactively communicate with policyholders about upcoming renewals, providing assistance and ensuring continued coverage.",
            whyChoose2h3: "Billing and Payment Support:",
            whyChoose2p3: "Assist policyholders with billing inquiries, payment processing, and resolution of payment-related issues.",
            whyChoose2h4: "Fraud Prevention and Security:",
            whyChoose2p4: "Implement robust measures to identify and prevent fraudulent activities, safeguarding your business and policyholders.",
            whyChoose2h5: "Customer Retention Strategies:",
            whyChoose2p5: "Develop and implement strategies to enhance customer retention, promoting long-term relationships with policyholders.",
            exprienceh: "Partner with StaffWiz for Insurance Excellence",
            expriencep: "At StaffWiz, we go beyond providing support; we become an extension of your insurance team, dedicated to safeguarding the confidence of your policyholders. Elevate your insurance services with our customized BPO call center solutions. Contact us today to explore how we can tailor our services to meet the unique needs of your insurance business.",
            title: "Insurance BPO | Insurance Outsourcing | Insurance Outsourcing Companies",
            desc: "Optimize your operations with insurance BPO services from Staffwiz. We provide expert insurance outsourcing solutions, partnering with top insurance outsourcing companies.",

        },
        "/real-state-service": {
            mainHeading: "Insurance Support Safeguards Confidence and Enhances Trust",
            about: "In the dynamic world of real estate, where every transaction is a significant milestone, exceptional customer support is the foundation of lasting client-agent relationships. StaffWiz specializes in providing BPO call center services tailored for the real estate industry. From property inquiries to transaction support, trust StaffWiz to be your dedicated partner in delivering top-tier assistance for your clients.",
            bannerImage: "Untitled-8.png",
            aboutImage: "a7.png",
            whyChoose: "Why Choose StaffWiz for Your Real Estate Support?",
            whyChooseh1: "Real Estate Specialists at Your Service:",
            whyChoosep1: "Our real estate team's deep industry knowledge enables us to provide specialized support, enhancing client satisfaction.",
            whyChooseh2: "Multichannel Engagement:",
            whyChoosep2: "In insurance, StaffWiz ensures compliance and confidentiality by strictly following data protection regulations for policyholder security.",
            whyChooseh3: "Transaction Coordination:",
            whyChoosep3: "We offer seamless omnichannel support via phone, email, chat, and social media for a consistent policyholder experience.",
            whyChooseh4: "Property Information and Tours:",
            whyChoosep4: "Efficiently handle claims inquiries, guiding policyholders through the process and expediting the resolution of their claims.",
            whyChooseh5: "Contract and Documentation Assistance:",
            whyChoosep5: "Assist clients with contract-related inquiries, ensuring clarity and facilitating smooth documentation processes.",
            whyChoose2: "Our Real Estate BPO Call Center Services Include:",
            whyChoose2h1: "Client Onboarding:",
            whyChoose2p1: "Welcome new clients and guide them through the onboarding process, establishing a positive and lasting first impression.",
            whyChoose2h2: "Open House Coordination:",
            whyChoose2p2: "Facilitate the coordination of open houses, ensuring a seamless experience for both clients and prospective buyers.",
            whyChoose2h3: "Lead Qualification and Follow-up:",
            whyChoose2p3: "Qualify leads and implement timely follow-up strategies, maximizing conversion opportunities for real estate transactions.",
            whyChoose2h4: "Appointment Scheduling:",
            whyChoose2p4: "Efficiently manage appointments for property viewings and consultations, ensuring optimal time management for clients and agents.",
            whyChoose2h5: "Customer Feedback Management:",
            whyChoose2p5: "Gather valuable insights through customer feedback management, helping real estate agencies refine their services and exceed client expectations.",
            exprienceh: "Partner with StaffWiz for Real Estate Excellence",
            expriencep: "At StaffWiz, we don't just provide support; we become an integral part of your real estate team, dedicated to building success one relationship at a time. Elevate your real estate services with our customized BPO call center solutions. Contact us today to explore how we can tailor our services to meet the unique needs of your real estate business.",
            title: "Real Estate BPO Services | BPO Real Estate | CRM for Real Estate Agent - Staffwiz",
            desc: "Streamline your operations with real estate lead management and BPO real estate services from Staffwiz. Optimize your sales with CRM for real estate agents and expert support.",

        }
    })

    useEffect(() => {
        let data = listData[location.pathname]
        setData(data)
    }, [])
    return (
        <>
        <Helmet>
            <title>{data?.title}</title>
            <meta name="description" content={data?.desc} />
        </Helmet>
        <section className="home-banners landing-bnr">
                <div className="container">
                <div className="home-banner">
                    <div className="row align-items-end">
                    
                        <div className="col-lg-7 right-info">
                            <h1>{data?.mainHeading}</h1>
                            {/* <h3 className="my-4">Elevate your business with our <br />BPO & call center services.</h3> */}

                           
                            {/* <div className="home-rating-banner mt-4">
                                <h6><img src="assets/images/starts.svg" alt="review stars"></img> <span>Reviews by clients worldwide</span></h6>
                            </div> */}
                            <img src={`assets/images/${data?.bannerImage}`} alt="" className="ladyImg"/>
                    </div>
                    {/* <div className="col-lg-4">
                            <img
                                src="assets/images/home-banner1.png"
                                className="img-fluid" alt="instruction"
                            ></img>
                        </div> */}
                        
                        
                        <div className="col-lg-5">
                            <span className="c1"></span>
                            <h2>Set Up Your Call Center</h2>
                            <span className="l1"></span>
                      
                            <GetStartedForm
                                heading={"READY TO GET STARTED?"} />

                        </div>
                    </div>
                    

                </div>
                <div className="green-banner">
                    
                    </div>
                </div>
                
            </section>
            <section className="landing-about">
                <div className="container">
                    <div className="row">
<div className="grn-circle">
<img src="assets/images/Line 15.png" alt="" className="grn-line"></img>
<div className="text">
<img src="assets/images/grn-circle.png" alt=""></img>
    <h2>96%</h2>
    <p>Client Satisfaction</p>
</div>
<div className="text">
<img src="assets/images/grn-circle.png" alt=""></img>
    <h2>500+</h2>
    <p>Projects</p>
</div>
<div className="text">
<img src="assets/images/grn-circle.png" alt=""></img>
    <h2>200+</h2>
    <p>Happy Agents!</p>
</div>
</div>
<div className="col-lg-5 mx-auto">
<img src={`assets/images/${data?.aboutImage}`} alt=""/>
</div>
<div className="col-lg-7 mx-auto">
<p>{data?.about}</p>
</div>
</div></div></section>
            <section className="landing-why">
                <div className="container">
                    <div className="row">
                        <h3>{data?.whyChoose}</h3>
                    <div className="col-lg-4 mx-auto">
                    <img src="assets/images/check 1.png" alt=""></img>
                    <div className="txt">
                        <h6>{data?.whyChooseh1}</h6>
                        <p>{data?.whyChoosep1}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mx-auto">
                    <img src="assets/images/check 1.png" alt=""></img>
                    <div className="txt">
                        <h6>{data?.whyChooseh2}</h6>
                        <p>{data?.whyChoosep2}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mx-auto">
                    <img src="assets/images/check 1.png" alt=""></img>
                    <div className="txt">
                        <h6>{data?.whyChooseh3}</h6>
                        <p>{data?.whyChoosep3}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mx-auto">
                    <img src="assets/images/check 1.png" alt=""></img>
                    <div className="txt">
                        <h6>{data?.whyChooseh4}</h6>
                        <p>{data?.whyChoosep4}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mx-auto">
                    <img src="assets/images/check 1.png" alt=""></img>
                    <div className="txt">
                        <h6>{data?.whyChooseh5}</h6>
                        <p>{data?.whyChoosep5}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mx-auto">
                   
                    <div className="btn">
<a href="/schedule-call" >Book a Discussion</a>
                        </div>
                    </div>
                        </div></div></section>
                        <section className="landing-why bgwhite">
                <div className="container">
                    <div className="row">
                        <h3>{data?.whyChoose2}</h3>
                        <div className="col-lg-4 mx-auto">
                    <img src="assets/images/check 1.png" alt=""></img>
                    <div className="txt">
                        <h6>{data?.whyChoose2h1}</h6>
                        <p>{data?.whyChoose2p1}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mx-auto">
                    <img src="assets/images/check 1.png" alt=""></img>
                    <div className="txt">
                        <h6>{data?.whyChoose2h2}</h6>
                        <p>{data?.whyChoose2p2}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mx-auto">
                    <img src="assets/images/check 1.png" alt=""></img>
                    <div className="txt">
                        <h6>{data?.whyChoose2h3}</h6>
                        <p>{data?.whyChoose2p3}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mx-auto">
                    <img src="assets/images/check 1.png" alt=""></img>
                    <div className="txt">
                        <h6>{data?.whyChoose2h4}</h6>
                        <p>{data?.whyChoose2p4}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mx-auto">
                    <img src="assets/images/check 1.png" alt=""></img>
                    <div className="txt">
                        <h6>{data?.whyChoose2h5}</h6>
                        <p>{data?.whyChoose2p5}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mx-auto">
                   
                    <div className="btn">
<a href="/schedule-call" >Contact Sales</a>
                        </div>
                    </div>
                    </div></div></section>
            <section className="landing-advantage">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-6 mx-auto"><img src="assets/images/shutterstock_2159023891 1.png" alt=""></img></div>
                    <div className="col-lg-6 mx-auto">
                        <h3>{data?.exprienceh}</h3>
                        <p>{data?.expriencep}</p>
                    </div>
                        </div></div></section>
                        <section className="landing-started">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-1 mx-auto"></div>
                    <div className="col-lg-5 mx-auto">
                        <h3>Get <span>Started</span> Today!</h3>
                        <div>
                        <img src="assets/images/Group 119.png" alt=''></img>
                        <p>Efficient Call Center Solutions</p>
                        </div>
                        <div>
                        <img src="assets/images/Group 119.png" alt=''></img>
                        <p>Comprehensive BPO Services</p>
                        </div>
                        <div>
                        <img src="assets/images/Group 119.png" alt=''></img>
                        <p>Flexible Staff Augmentation</p>
                        </div>
                        
                    </div>
                    <div className="col-lg-6 mx-auto"><img src="assets/images/Ellipse 92.png" alt="" className="ldy"></img>
                    <div className="btn">
<a href="/schedule-call" >Speak with an Expert</a>
                        </div>
                    </div>
                        </div></div></section>
           
                        <section className="client-word bgGray">
            <div className="container">
                <h2 className="heading">Clients <span>Words</span></h2>
                <div id="customers-testimonials" class="owl-carousel">
                <div className="item">
                        <div className="card">
                            <div className="card-body">
                                <img className="img-fluid" src="assets/images/rating.svg" alt="rating" />
                                <h5>Sue Robertson - Suntree Internal Medicine</h5>
                                <p>My Staffwiz transcriptionists are the BEST! Very knowledgeable in medical terminology and dependable.  Whenever I'm in need of another transcriptionist I wouldn't hesitate to take on another Staffwiz MT. </p>
                            </div>
                        </div>
                    </div>
            <div className="item">
                        <div className="card">
                            <div className="card-body">
                                <img className="img-fluid" src="assets/images/rating.svg" alt="rating" />
                                <h5>Julie Leach - Grace Accounting, LLC</h5>
                                <p>StaffWiz (specifically Jerick O.) has been instrumental into the growth of our organization with the candidates that he has presented (and onboarded) for us. I personally was skeptical of hiring individuals that were going to be fully remote (and also living within another country), but the experience that Jerick has provided our organization makes me believe in the greater global economy and I couldn't be happier with the result. This is truly one of the best decisions that we have ever made for our organization. I would highly recommend StaffWiz to anyone that is looking for quality individuals to grow their organization.</p>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="card">
                            <div className="card-body">
                                <img className="img-fluid" src="assets/images/rating.svg" alt="rating" />
                                <h5>Amelie Peterson - TMC inc</h5>
                                <p>hesitantly hired a VA ( virtual assistant) for my E commerce business through Staffwiz in the Philippines, I was skeptical that an offshore staff would not be comparable to an American employee, but I have to say my VA proved me wrong she is simply phenomenal.</p>
                            </div>
                        </div>
                    </div>

                    <div class="item">
                        <div class="card">
                            <div class="card-body">
                                <img class="img-fluid" src="assets/images/rating.svg" alt="rating" />
                                <h5>D Bhatta - Bhatta Law llc</h5>
                                <p>My Law firm gets a lot of inbound calls concerning immigration matters, we needed a couple of customer support reps to assist us with this. staffwiz found a great team for us we are very happy with them.</p>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="card">
                            <div class="card-body">
                                <img class="img-fluid" src="assets/images/rating.svg" alt="rating" />
                                <h5>Pravez Gondal - Gondal LLC</h5>
                                <p>Staffwiz helped me find medical billing staff for my Medical staffing business at a great rate, they really match you with a team that has the right skillset youneed for your business. I hope to hire more staff in the next 3 months.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="assets/images/Ellipse 91.png" alt="" className="service-img" />
            </div>
            
        </section>

            
        </>
    );
};
export default SkilledProfessionals;
